import { Injectable } from '@angular/core';
import { WindowRef } from '@x/common/browser';
import { SessionStorage } from '@x/common/storage';

export type ScrollKey = 'pl' | 't' | 'ps';
export type ScrollPositionData = { count?: number; offset: number };
export type ScrollPosition = Record<string, ScrollPositionData>;
export type ScrollContext = { key: ScrollKey; positionKey: string | number };

export interface DeactivateScrollCmp {
  totalCount?: number;
}

@Injectable()
export class ShopScrollService {
  constructor(
    private storage: SessionStorage,
    private windowRef: WindowRef,
  ) {}

  set scrollPosition(scroll: { key: ScrollKey; position: ScrollPosition }) {
    const { key, position } = scroll;

    const updatedPosition = { ...this.getScrollPosition(key), ...position };

    this.storage.setItem(`${key}-scroll-position`, updatedPosition);
  }

  getScrollPosition(key: ScrollKey): ScrollPosition | undefined {
    return this.storage.getItem(`${key}-scroll-position`);
  }

  removeScroll(key: ScrollKey, param: string) {
    const position = this.getScrollPosition(key);

    if (position && param in position) {
      const update = { ...position };
      delete update[param];

      this.storage.setItem(`${key}-scroll-position`, update);
    }
  }

  restoreScrollPosition(key: ScrollKey, param: string) {
    try {
      const savedScrollPosition = this.getScrollPosition(key)?.[param];

      if (!savedScrollPosition) return;

      const { offset } = savedScrollPosition;
      setTimeout(() => {
        this.windowRef.instance.scrollTo({
          top: offset,
          behavior: 'smooth',
        });

        this.removeScroll(key, param);
      }, 100);
    } catch (e) {
      console.log('could not restore scroll position');
      this.removeScroll(key, param);
    }
  }
}
